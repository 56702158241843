/* App.css */
.container {
  position: absolute;
  width: 100%;
  height: 200vh;
  z-index: 1; /* Ensure this is behind the .App */
  overflow: hidden;
}

#hex-grid {
  height: 100%;
  background: #112D4E;
}

#hex-grid .grid {
  position: absolute;
  top: 0;
  left: 0;
  background: url("./components/files/grid.svg") repeat;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: 40%;
}

#hex-grid .light {
  position: absolute;
  top: 50px;
  left: 50px;
  transform: translate(-50%, -50%);
  width: 20em;
  height: 20em;
  border-radius: 50%;
  filter: blur(15px);
  background: linear-gradient(90deg, #3F72AF 0%, #DBE2EF 100%);
  z-index: 0;
  pointer-events: none; /* Ensure it doesn't capture mouse events */
}

.App {
  position: relative;
  z-index: 2;
  background: transparent;
  min-height: 100%; /* make sure the height is at least 100% of the viewport height */
  width: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* smooth scrolling */
body, html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden; /* prevents horizontal overflow (scroll bar) */
  margin: 0;
  padding: 0;
}

.section {
  height: 100vh;
  display: flex;
  flex-direction: column; /* column layout for better responsiveness */
  width: 100%; /* ensures it does not exceed the viewport width */
}

#footer {
  height: 2vh;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .section {
    margin-top: 50px; /* offset sections by the new height of the navbar */
    height: calc(100vh - 50px); /* adjust section height accordingly */
  }
}

/* Custom Scrollbar styles */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background: radial-gradient(circle, #DBE2EF, #3F72AF);
  border-radius: 6px 0px 0px 6px; /* Rounded edges of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: radial-gradient(circle, #3F72AF, #112D4E);
}

::-webkit-scrollbar-track {
  background: #F9F7F7; /* Color of the scrollbar track */
}

/* media query to hide content on small screens */
@media (max-width: 1080px) {
  .container, .App {
    display: none;
  }
}

/* style for the small screen message */
.small-screen-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  text-align: center;
  padding: 20px;
  color: #3F72AF;
}
