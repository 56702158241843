/* color for navbar when on active page */
.active { 
  background-color: #3F72AF;
}

/* navbar styling */
.navbar {
  position: fixed; /* fixed instead of absolute to avoid scrolling issues */
  top: 0;
  left: 0; 
  width: 100%; 
  height: 60px; /* specify the height of the navbar */
  background-color: #F9F7F7;
  padding: 1em; 
  z-index: 1000;
  display: flex;
  justify-content: space-between; /* distributes space between logo and navbar items */
  align-items: center; /* center align items vertically */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

/* logo styling */
.logo {
  font-size: 2em;
  font-weight: bold;
  margin-left: 8%; 
}

/* menu toggle button styling */
.menu-toggle {
  display: none; /* hidden by default */
  font-size: 2em;
  background: none;
  border: none;
  cursor: pointer;
}

/* navbar items styling */
.navbar-list {
  list-style: none;
  display: flex; /* display items in a row */
  margin: 0;
  padding: 0;
  margin-right: 8%; 
}

.navbar-item a {
  color: black;
  text-decoration: none;
  font-size: large;
  padding: 0.5em 1em;
  font-weight: bold;
  border-radius: 0.5rem;
}

.navbar-item a:hover {
  background-color: #DBE2EF; /* changes hover background color on navbar items */
  cursor: pointer;
}

/* responsive design for smaller screens */
@media (max-width: 768px) {
  .menu-toggle {
    display: block; /* shows toggle button */
  }

  .logo {
    margin-left: 2%; /* changed for smaller screens */
  }

  .navbar {
    height: 50px; /* adjust navbar height for smaller screens */
  }

  .navbar-list {
    flex-direction: column;
    width: 100%;
    display: none; /* hides menu by default */
    position: absolute;
    top: 50px; /* adjust navbar height */
    left: 0;
    background-color: #eee; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }

  .navbar-list.open {
    display: flex; /* shows menu when toggled */
  }

  .navbar-item {
    width: 100%;
    text-align: center;
  }

  .navbar-item a {
    display: block;
    width: 100%;
    padding: 0.5rem 0.5rem;
    margin: 0.2rem 0.5rem;
  }
}
