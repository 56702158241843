.projects-container {
  padding: 20px;
  text-align: center;
}

.projects-container h1 {
  color: #112D4E;
  text-shadow: 3px 3px 2px rgba(63, 114, 175, 0.9);
  -webkit-text-stroke: 0.5px #F9F7F7;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 20px;
  justify-items: center;
}

.project-card {
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  height: 200px;
  width: 100%;
  max-width: 500px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s, background-color 0.3s, height 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px 5px rgba(63, 114, 175, 0.5);
}

.project-card.expanded {
  height: 450px;
}

.project-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: blur(2px);
  transition: filter 0.3s;
  z-index: 0;
}

.project-card.expanded .project-image {
  filter: blur(0px);
}

.project-content {
  position: relative;
  z-index: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-title {
  font-size: 1.5em;
  color: #F9F7F7;
  text-shadow: 0 0 5px #112D4E, 0 0 5px #112D4E;
  margin: 10px 0;
}

.project-description {
  background-color: rgba(249, 247, 247, 0.9);
  width: 90%;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-size: 1em;
  color: #3F72AF;
  padding: 20px;
  margin-top: 10px; 
}

.github {
  font-size: 1.5em;
  color: #3F72AF;
  background-color: #DBE2EF;
  border: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-top: 10px;
  transition: transform 0.1s ease;
}

.skills-icons {
  display: flex;
  gap: 10px;
}

.skill-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  background-color: rgba(255, 255, 255, 1); 
  padding: 2px; 
  border-radius: 4px; 
  display: inline-block; 
}

