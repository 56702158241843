.home {
    margin-top: 60px; /* navbar height offset */
}

.home-section {
    display: grid;
    grid-template-columns: 1fr 1fr; /* two equal columns */
    grid-template-rows: auto auto; /* adjust rows based on content */
    height: 100vh;
    width: 100%;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    position: relative;
    left: 8%;
}

.home-section.blurred {
    filter: blur(5px); /* applies blur effect when contact form is open */
}

.description {
    position: absolute;
    margin-top: 14%;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    background: #F9F7F7;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.11);
    max-width: 80%;
    min-width: 320px;
    min-height: 300px;
    width: 100%; 
    padding: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    --border-angle: 0turn;
    --main-bg: conic-gradient(
        from var(--border-angle),
        #FFF,
        #FFF 5%,
        #FFF 60%,
        #FFF 95%
    );

    border: solid 6px transparent;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #DBE2EF, #3F72AF 100%, transparent);

    background: 
        var(--main-bg) padding-box,
        var(--gradient-border) border-box, 
        var(--main-bg) border-box;

    background-position: center center;
    animation: bg-spin 10s linear infinite;
}

.button-container {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.button-container .resume, .contact {
    font-size: 100%;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 700; /* bold */
    height: 40px;
    width: 140px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease; /* Smooth transition */
}

.resume {
    color: #DBE2EF;
    background-color: #3F72AF;
    border: 2px solid #3F72AF;
}

.contact {
    color: #112D4E;
    background-color: #DBE2EF;
    border: 2px solid #3F72AF;
}

.button-container .github, .linkden {
    font-size: 1.5em;
    color: #112D4E;
    background-color: #DBE2EF;
    border: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: transform 0.5s ease; /* smooth transition */
}

.resume:hover, .contact:hover, .linkden:hover, .github:hover {
    transform: scale(1.15); 
}

.resume:hover, .contact:hover, .linkden:hover, .github:hover {
    color: #112D4E;
    --border-angle: 0turn;
    --main-bg: conic-gradient(
        from var(--border-angle),
        #FFF,
        #FFF 5%,
        #FFF 60%,
        #FFF 95%
    );

    border: solid 3px transparent;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #DBE2EF, #3F72AF 100%, transparent);

    background: 
        var(--main-bg) padding-box,
        var(--gradient-border) border-box, 
        var(--main-bg) border-box;

    background-position: center center;
    animation: bg-spin 3s linear infinite;
}

h1, h2, h3 {
    margin: 1% 0;
}

h1 {
    font-size: 5rem;
    color: #000;
}

h2 {
    font-size: 2.5rem;
    color: #3F72AF;
}

h3 {
    color: #000;
    font-size: 1.2rem;
}

/* Contact Popup */
.contact-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
  
.contact-form {
    background-color: white;
    padding: 2%;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    text-align: center;
}
  
.contact-form h2 {
    margin-bottom: 2%;
}
  
.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 1%;
    margin: 1% 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}
  
.contact-form button {
    padding: 1% 2%;
    margin-top: 1%;
    border: none;
    background-color: #3F72AF;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
  
.close-button {
    margin-top: 2%;
    padding: 1% 2%;
    border: none;
    background-color: #ccc;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.picture-container {
    grid-column: 2 / 3;
    grid-row: 1 / span 2;
    position: relative;
    display: flex;
    max-width: 50%;
    max-height: 50%;
    height: 100%;
    width: 100%;
    left: 6%;
    top: -4%;
}

.picture {
    position: relative;
    z-index: 3; /* ensure the image is on top */
}

.picture img {
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    width: 100%; /* responsive width */
    height: 100%; /* responsive height */
    object-fit: cover;
    z-index: 3;
}

.picture-background-1, .picture-background-2 {
    position: absolute;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    z-index: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.picture-background-1 {
    background-color: #F9F7F7; 
    top: -3%; 
    left: 3%; 
    z-index: 2;
}

.picture-background-2 {
    background-color: #DBE2EF; 
    top: -6%; 
    left: 6%; 
    z-index: 1;
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}